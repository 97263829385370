<template>
  <div class="map-section">
    <div class="map-section__title">
      <div class="content">Карта лечебных учреждений, в&nbsp;которых возможно получить иммунизацию препаратом Эвушелд</div>
    </div>
    <div class="content">
      <div class="map-section__tools">
        <div class="map-section__view">
          <div class="map-section__label mb-4">Отображение</div>
          <div class="map-section__buttons">
            <div
              class="map-section__button button mr-4"
              @click="activeButton = 0"
              :class="
                activeButton === 0
                  ? 'button_empty-pink'
                  : 'button_empty-dark-gray'
              "
            >
              Карта
            </div>
            <div
              class="map-section__button button"
              @click="toList()"
              :class="
                activeButton === 1
                  ? 'button_empty-pink'
                  : 'button_empty-dark-gray'
              "
            >
              Список
            </div>
          </div>
        </div>
        <div
          class="map-section__count-container d-none d-xl-block"
          v-if="currentRegion.id"
        >
          ЛПУ в выбранном регионе:
          <b class="ml-2">{{ mapItems.items.length }}</b>
        </div>
        <div
          class="map-section__region"
          v-if="mapItems && mapItems.areas && mapItems.areas.length"
        >
          <div class="map-section__label mb-4">Регион</div>
          <Select
            v-if="mapItems && mapItems.areas && mapItems.areas.length"
            v-model="region"
            :searchable="true"
            :searchEmpty="'Извините, такого региона нет.'"
            :items="regions"
            :placeholder="'Выбрать область из списка'"
          />
          <div
            class="map-section__count-container d-xl-none"
            v-if="currentRegion.id"
          >
            ЛПУ в выбранном регионе:
            <b class="ml-2">{{ mapItems.items.length }}</b>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeButton === 0">
      <yandex-map
        :key="'ymaps_' + region"
        v-bind="mapSettings"
        ymap-class="map-section__map"
        :coords="coords"
        :settings="settings"
        :cluster-options="clusterOptions"
        v-if="mapItems && mapItems.items && mapItems.items.length"
        :scroll-zoom="true"
      >
        <!--Markers-->
        <ymapMarker
          v-for="marker in markers"
          :key="marker.markerId"
          :icon="icon"
          v-bind="marker"
          :cluster-name="marker.area"
        ></ymapMarker>
      </yandex-map>
    </div>

    <div class="content" v-else>
      <div class="map-section__list">
        <div
          class="map-section__list-item"
          :class="{
            'map-section__list-item_opened': activeListItem === area.id ,
          }"
          v-for="area in mapItems.areas"
          :key="area.id"
          v-show="getAreaItems(area).length > 0"
        >
          <div class="map-section__list-item-head" @click="openItem(area.id)">
            <div class="map-section__list-item-title">
              {{ area.name }}
              <sup>{{ getAreaItems(area).length }}</sup>
            </div>
            <div class="map-section__list-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 11L17 21L7 11"
                  stroke="#3C4242"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div class="map-section__list-item-body">
            <div
              class="map-section__item"
              v-for="item in getAreaItems(area)"
              :key="item.id"
            >
              <div class="map-section__item-name">
                {{ item.name }}
              </div>
              <div class="map-section__item-address">
                {{ item.address }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="bottom-tip">
      <div class="content" v-if="pageVerify && pageVerify.length">
        {{
          `Номер одобрения: ${pageVerify[0].number} Дата одобрения: ${this.$m(
            pageVerify[0].approval_date
          ).format("DD.MM.YYYY")}. Дата истечения: ${this.$m(
            pageVerify[0].expire_date
          ).format("DD.MM.YYYY")}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/form-elements/Select.vue";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Map",
  data: () => ({
    timer: null,
    activeButton: 0,
    activeListItem: null,
    region: [""],
    coords: ["53.197525", "50.096079"],
    clusterOptions: {
      az: {
        clusterIcons: [
          {
            href: "/content/podcasts/Ellipse.png",
            size: [40, 40],
            offset: [-20, -20],
          },
        ],
      },
    },
    settings: {
      apiKey: "0a67dedd-42fc-430d-a039-6e777625ba0a",
      lang: "ru_RU",
      coordorder: "latlong",
      enterprise: false,
      version: "2.1",
    },
    mapSettings: {
      zoom: 4,
      controls: ["geolocationControl", "zoomControl"],
    },
    icon: {
      layout: "default#image", // 'default#imageWithContent' для использования с контентом
      imageHref:
        "data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26 4.875C34.7503 4.875 42.25 12.3747 42.25 21.125C42.25 29.8753 26 47.125 26 47.125C26 47.125 9.75 29.8753 9.75 21.125C9.75 12.3747 17.2497 4.875 26 4.875Z' fill='%23D0006F'/%3E%3Crect x='17.875' y='13' width='16.25' height='16.25' rx='8.125' fill='white'/%3E%3C/svg%3E%0A", // адрес изображения или data:image/svg+xml;base64
      imageSize: [43, 55], // размер иконки в px
      imageOffset: [-22, -55], // смещение иконки в px,
      /* Следующие поля актуальны для layout: 'default#imageWithContent' */
      content: "", // содержимое контента
      contentOffset: [0, 0], // смещение контента в px,
      contentLayout: "", // строковый HTML шаблон для контента
    },
  }),
  components: {
    Select,
    yandexMap,
    ymapMarker,
  },
  computed: {
    ...mapGetters(["mapItems", 'pageVerify']),
    regions() {
      const vm = this
      if (this.mapItems && this.mapItems.areas && this.mapItems.areas.length) {
        return [
          {
            label: "Все",
            value: "",
            coords: "53.197525, 50.096079",
            zoom: 4,
          },
        ].concat(
          this.mapItems.areas.map(function (el) {
            return {
              label: el.name,
              value: el.id,
              coords: `${el.latitude}, ${el.longitude}`,
              zoom: 7,
            };
          })
        );
      } else {
        return [
          {
            label: "Все",
            value: "",
            coords: "53.197525, 50.096079",
            zoom: 4,
          },
        ];
      }
    },
    currentRegion() {
      let region = this.regions.find((el) => el.value === this.region[0]);
      if (!region) {
        return {
          coords: this.region[0],
          zoom: 4,
        };
      }
      return {
        id: region.value,
        coords: region.coords.split(","),
        zoom: region.zoom || 9,
      };
    },
    markers() {
      const vm = this;
      return this.mapItems.items.map(function (item) {
        return {
          markerId: item.id,
          hideIconOnBalloonOpen: false,
          area: "az",
          coords: [item.latitude, item.longitude],
          "balloon-template": vm.balloonTemplate(item.name, item.address),
          options: {
            hideIconOnBalloonOpen: false,
            syncOverlayInit: true,
            balloonOffset: [0, -30],
          },
        };
      });
    },
  },
  methods: {
    ...mapActions(["fetchMapItems", 'fetchPageVerify']),
    toList() {
      this.activeButton = 1;
      this.activeListItem = this.currentRegion.id;
    },
    openItem(id) {
      this.activeListItem === id
        ? (this.activeListItem = null)
        : (this.activeListItem = id);
    },
    balloonTemplate(title, address) {
      return `
        <div class="baloonTitle">${title}</div>
        <div class="baloonAdress">${address}</div>
      `;
    },
    getAreaItems(area) {
      return this.mapItems.items.filter((el) => el.region.id === area.id);
    },
  },
  async mounted() {
    this.fetchMapItems();
    await this.fetchPageVerify(this.$route.path);
  },
  watch: {
    currentRegion() {
      if (this.mapItems && this.mapItems.areas && this.mapItems.areas.length) {
        this.coords = this.currentRegion.coords;
      }
    },
    region() {
      this.fetchMapItems(this.currentRegion.id);
      this.mapSettings.zoom = this.currentRegion.zoom;
      this.activeListItem = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-section {
  &__title {
    margin-bottom: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #fff;
    background: #830051;

    @media screen and (max-width: 1220px) {
      padding-top: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #b2b4b4;
  }

  &__tools {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 24px;

    > * {
      flex-shrink: 0;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      align-items: flex-start;
      margin-bottom: 16px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  &__button {
    width: 82px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    @media screen and (max-width: 767px) {
      width: 50%;
    }
  }

  &__count-container {
    margin-bottom: 8px;
    margin-left: 47px;
    margin-right: 47px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #b2b4b4;

    & b {
      font-weight: bold;
      color: #1f1f1f;
    }

    @media screen and (max-width: 1220px) {
      margin-bottom: 0;
      margin-left: 0;
      margin-top: 8px;
    }
  }

  &__region {
    margin-left: auto;
    width: 280px;

    @media screen and (max-width: 1220px) {
      margin-left: auto;
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 24px;
    }
  }

  &__list-item {
    margin-bottom: 16px;
    background: #f8f8f8;
    border-radius: 24px;
    transition: 0.3s;
    cursor: pointer;

    @media screen and (min-width: 1221px) {
      &:hover:not(.map-section__list-item_opened) {
        background-color: #f6cce2;

        .map-section__list-item-title {
          color: #1f1f1f;
        }
      }
    }

    &-head {
      padding: 24px 48px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 767px) {
        padding: 8px 16px;
      }
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #830051;
      transition: 0.3s;
      margin-right: 10px;

      & > sup {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 29px;
        color: #1f1f1f;
        vertical-align: super;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-body {
      max-height: 0;
      overflow: hidden;
      padding: 0 48px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 16px;
      }
    }

    &-arrow {
      transition: 0.3s;
      transform: rotate(0);
      margin-left: auto;
      flex-shrink: 0;
      cursor: pointer;

      & > svg {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 767px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__list-item_opened {
    .map-section__list-item {
      &-arrow {
        transform: rotate(180deg);
      }

      &-body {
        max-height: 9999999px;
        padding-bottom: 24px;
      }
    }
  }

  &__item {
    &-name {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1f1f1f;
    }

    &-address {
      margin-top: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3c4242;
    }
  }
}

.bottom-tip {
  
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b2b4b4;

  & .content {
    padding: 10px 0;
  }
}
</style>

<style lang="scss">
.map-section {
  &__map {
    width: 100vw;
    // height: calc(100vh - 128px - 125px);
    height: calc(100vh - 128px - 84px);
    min-height: 500px;
  }

  .baloonTitle {
    margin-bottom: 4px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
  }

  .baloonAdress {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
  }
}
</style>